.CreateAppointment {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }

  .dx-appointment-form-switch{
    visibility: hidden;
    height: 0px;
  }


  .dx-item-content{color: #000;
    font-size: 12px;
    font-weight: 500;}

    .dx-scheduler-appointment-collector-content{
      color: #fff !important;
    }
    .dx-scheduler-appointment-collector.dx-button{
      color: #337ab7 !important;
      background-color: #337ab7 !important;
    }