.appointment-preview{
    font-size: 16px;
    font-weight: bold;

}

.title-preview{
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
}

.date-preview{
    font-size: 13px;
    font-weight: 600;
}

.phone-preview{
    font-size: 13px;
    font-weight: 600;
}

.description-preview{
    font-size: 13px;
    font-weight: 600;
}