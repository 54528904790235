.custom-icon .icon {
    font-size: 17px;
    color: #f05b41;
    margin-right: 2px;
  }
  
  .dx-field {
    margin-bottom: 50px;
  }


  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .dx-popup-wrapper > .dx-overlay-content {
    width: 450px !important;
    height: 700px !important;
    left: 20px !important;
    top: 20px !important;
  }
  
  #podologoInfo,#popUpPodologos{
    width: 450px !important;
    height: 700px !important;
    left: 20px !important;
    top: 20px !important;

  }

  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .dx-popup-wrapper > .dx-overlay-content {
    width: 450px !important;
    height: 800px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .dx-popup-wrapper > .dx-overlay-content {
    width: 700px !important;
    height: 600px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .dx-popup-wrapper > .dx-overlay-content {
    width: 700px !important;
    height: 600px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .dx-popup-wrapper > .dx-overlay-content {
    width: 700px !important;
    height: 600px !important;
  }
}